import {
  Box,
  FormGroup,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const CustomPhoneNumber = ({
  formik,
  CountryDetails,
  handleChangeCountry,
  setOpen,
  open,
  handleIconClick,
  setCountryId,
  onBlur,
  error,
  helperText,
  value,
  valuePhone,
  onChange,
  name,
}) => {
  return (
    <>
      <FormGroup sx={{ marginY: "0px" }}>
        <InputLabel
          sx={{
            fontWeight: 400,
            py: "4px",
            color: "#F2F2F2",
            textAlign: "start",
          }}
        >
          Mobile Number
        </InputLabel>
        <Box sx={{ display: "flex", gap: 1 }}>
          <TextField
            // sx={{ width: "12.5em" }}
            fullWidth
            sx={{
              width: "10em",
              borderRadius: "20px",
              color: "black",
              height: { xs: "45px", sm: "45px" },
              background: "#F2F2F2",

              "& fieldset": {
                border: "none",
              },

              "& .MuiInputBase-input ": {
                color: "black",
                // background: "#F2F2F2",
                borderRadius: "20px",
                // height: "10px",
                padding: "13px 14px",
              },
            }}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            select
            required
            onChange={(event) => {
              handleChangeCountry(event); // Call the handleChangeCountry function
            }}
            value={value}
            SelectProps={{
              IconComponent: null,
              IconProps: { sx: { color: "gray", marginRight: 0 } }, // Adjust styles for the icon
              open: open, // Pass the open state to SelectProps
              onOpen: () => setOpen(true), // Open the dropdown when this function is called
              onClose: () => setOpen(false),
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" onClick={handleIconClick}>
                  <KeyboardArrowDownIcon sx={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          >
            {CountryDetails &&
              CountryDetails.map((item) => (
                <MenuItem
                  // onClick={() => setCountryId(item.id)}
                  key={item.id}
                  value={item.isd_code}
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <Avatar
                      src={item.flag_image_url}
                      sx={{
                        height: "20px",
                        width: "20px",
                      }}
                    /> */}
                    <span style={{ color: "black" }}>{item.isd_code}</span>
                  </Box>
                </MenuItem>
              ))}
          </TextField>

          <TextField
            name={name}
            autoComplete="nope"
            required
            onBlur={onBlur}
            onChange={onChange}
            sx={{
              color: "black",

              "& fieldset": {
                border: "none",
              },

              "& .MuiInputBase-input ": {
                color: "black",
                background: "#F2F2F2",
                borderRadius: "20px",
                height: "10px",
              },
            }}
            // placeholder="Phone Number *"
            value={valuePhone}
            // inputProps={{
            //   style: { fontFamily: "Poppins", color: "black" },
            // }}
            type="tel"
            inputMode="tel"
            fullWidth
          />
        </Box>
        <FormHelperText sx={{ color: "red" }}>{helperText}</FormHelperText>
      </FormGroup>
    </>
  );
};
