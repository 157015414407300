import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import logo from "../assets/logo.png";

import { SignUpService, getRegistrationSMSOTP } from "./signUpservice";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const OtpPage = () => {
  const [otp, setOtp] = useState();
  const router = useHistory();
  const [isLoading, setLoading] = useState(false);

  const formikData = JSON.parse(sessionStorage.getItem("formData"));
  const handleSubmit = async () => {
    try {
      setLoading(true);
      await SignUpService(formikData, otp)
        .then((response) => {
          const data = response.data;
          setLoading(false);
          if (data?.status_code === 200) {
            toast.success(data?.message);
            router.push("/success");
            sessionStorage.clear();
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          setLoading(false);
        });
    } catch (error) {
      toast.error("Some think went wrong please try again after some times");
      setLoading(false);
    }
  };
  const url = new URL(window.location.href);
  const key = url.searchParams.get("ref");

  const handleResendOtp = async () => {
    try {
      setLoading(true);
      await getRegistrationSMSOTP(
        formikData.email,
        formikData.phone_number,
        formikData.full_name
      )
        .then((response) => {
          const data = response.data;
          setLoading(false);
          if (data?.status_code === 200) {
            toast.success(data?.message);
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          setLoading(false);
        });
    } catch (error) {
      toast.error("Some think went wrong please try again after some times");
      setLoading(false);
    }
  };
  return (
    <form>
      {isLoading === true && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div
        style={{
          // width: "100wh",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              p: 4,
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                border: 1,
                borderRadius: "30px",
                borderColor: "#9E6EFF",
                p: 3,
              }}
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <img
                    src={logo}
                    alt="LOGO"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                  <Typography>
                    Hey, our buddy {key ? key : ""} has invited you to become
                    the
                    <span style={{ color: "#9AFF91" }}> Cashback Ninja </span>
                  </Typography>
                </Box>
                <Box sx={{ py: 1 }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: { xs: "28px", lg: "30px" },
                    }}
                  >
                    Enter OTP
                  </Typography>
                  <Typography sx={{ py: 1 }}>
                    We have sent an OTP to {formikData?.email} Please enter down
                    below
                  </Typography>
                </Box>
                <Box
                  sx={{ width: { xs: "100%", sm: "90%" }, maxWidth: "500px" }}
                >
                  <FormGroup sx={{ marginY: "0px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 0.5,
                      }}
                    >
                      <InputLabel
                        sx={{ fontWeight: 400, py: 1, color: "#F2F2F2" }}
                      >
                        OTP
                      </InputLabel>
                    </Box>
                    <FormControl fullWidth>
                      <TextField
                        value={otp}
                        fullWidth
                        id="outlined-basic"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        autoComplete="off"
                        name="otp"
                        onChange={(e) => setOtp(e.target.value)}
                        sx={{
                          color: "black",

                          "& fieldset": {
                            border: "none",
                          },

                          "& .MuiInputBase-input ": {
                            color: "black",
                            background: "#F2F2F2",
                            borderRadius: "20px",
                            height: "10px",
                          },
                        }}
                      />
                    </FormControl>
                  </FormGroup>
                </Box>

                <Typography sx={{ p: 3 }}>
                  Didn’t receive one?{" "}
                  <span
                    style={{ color: "#9E6EFF", cursor: "pointer" }}
                    onClick={handleResendOtp}
                  >
                    Resend
                  </span>
                </Typography>

                {/* email */}
              </Grid>

              <Box
                sx={{
                  py: 3,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // alignSelf: "center",
                }}
              >
                <Grid item lg={8} xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      borderRadius: 5,
                      background: "#9E6EFF",
                      fontSize: "20px",
                    }}
                    onClick={handleSubmit}
                  >
                    Sign up
                  </Button>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
      </div>
    </form>
  );
};

export default OtpPage;
