import React from "react";

const Main = ({ children }) => (
  <div className="main-container">
    {/* <Header /> */}
    <main>{children}</main>
    {/* <Footer /> */}
  </div>
);

export default Main;
