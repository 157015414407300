import axios from "axios";

export const SignUpService = async (formikValues, otp) => {
  const signUpHeaders = {
    accept: "application/json",
  };
  const handler = axios.create({ headers: signUpHeaders });

  const payload = {
    phone_number: formikValues?.phone_number ? formikValues.phone_number : "",
    country_id: formikValues?.country_id ? formikValues.country_id : 0,
    full_name: formikValues?.full_name ? formikValues.full_name : "",
    otp: otp ? otp : "",
    email: formikValues?.email ? formikValues.email.toLowerCase() : "",

    password: formikValues?.password ? formikValues.password : "",
    referred_by: formikValues?.referred_by ? formikValues.referred_by : "",

    role_id: 5,
  };

  const signUpUrl = `${process.env.REACT_APP_THIRD_PARTY_API}/user-registration`;
  return await handler.post(signUpUrl, payload);
};

export const getRegistrationSMSOTP = async (email, phone_number, full_name) => {
  const emailLow = email.toLowerCase();
  return await axios.post(
    `${process.env.REACT_APP_THIRD_PARTY_API}/send-mail-otp?email=${emailLow}&phone_number=${phone_number}&role_id=5&template=USER_REGISTRATION&full_name=${full_name}`,
    {
      headers: {
        accept: "application/json",
      },
    }
  );
};
