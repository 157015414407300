// src/theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily:
      'Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    body1: {
      fontFamily: "Poppins, sans-serif",
      color: "white",
    },
    body2: {
      fontFamily: "Poppins, sans-serif",
      color: "white",
    },

    // Add more custom styles as needed
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @global {
          code {
            font-family: 'Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
          }
        }
      `,
    },
  },
});

export default theme;
