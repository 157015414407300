// import * as Yup from "yup";

// export const SignUpValidationSchema = Yup.object().shape({
//   password: Yup.string()
//     .max(255, "Password cannot be longer than 255 characters")
//     .required("New password is required")

//     .test(
//       "password-complexity",
//       "Password does not meet requirements",
//       (value) => {
//         if (!value) return true; // Don't validate if the field is empty

//         const passwordChecklist = {
//           minLength: value.length >= 8,
//           uppercase: /[A-Z]/.test(value),
//           lowercase: /[a-z]/.test(value),
//           number: /\d/.test(value),
//           // eslint-disable-next-line
//           specialCharacter: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?]+/.test(value),
//         };

//         return (
//           passwordChecklist.minLength &&
//           passwordChecklist.uppercase &&
//           passwordChecklist.lowercase &&
//           passwordChecklist.number &&
//           passwordChecklist.specialCharacter
//         );
//       }
//     ),

//   full_name: Yup.string().required(" Name is required"),

//   email: Yup.string()
//     .required("Email is required")
//     .matches(
//       /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
//       "Email is not valid"
//     )
//     .nullable(""),
//   promotion_status: Yup.boolean()
//     .oneOf([true], "You must agree to the Terms of Use and Privacy Policy")
//     .required("You must agree to the Terms of Use and Privacy Policy"),

//   phone_number: Yup.string().required("Phone number is required"),
// });

import * as Yup from "yup";

export const SignUpValidationSchema = Yup.object().shape({
  password: Yup.string()
    .max(255, "Password cannot be longer than 255 characters")
    .required("New password is required")
    .test(
      "min-length",
      "Password must be at least 8 characters",
      (value) => !value || value.length >= 8
    )
    .test(
      "uppercase",
      "Password must contain at least one uppercase letter",
      (value) => !value || /[A-Z]/.test(value)
    )
    .test(
      "lowercase",
      "Password must contain at least one lowercase letter",
      (value) => !value || /[a-z]/.test(value)
    )
    .test(
      "number",
      "Password must contain at least one number",
      (value) => !value || /\d/.test(value)
    )
    .test(
      "special-character",
      "Password must contain at least one special character",
      // eslint-disable-next-line
      (value) => !value || /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value)
    ),

  full_name: Yup.string().required("Name is required"),

  email: Yup.string()
    .required("Email is required")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Email is not valid"
    ),

  promotion_status: Yup.boolean()
    .oneOf([true], "You must agree to the Terms of Use and Privacy Policy")
    .required("You must agree to the Terms of Use and Privacy Policy"),

  phone_number: Yup.string().required("Phone number is required"),
});
