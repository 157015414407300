import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import SuccessMan from "../assets/successMan.png";
import appstore from "../assets/Appstore.png";
import playStore from "../assets/Playstore.png";

const SuccessPage = () => {
  return (
    <div
      style={{
        // width: "100wh",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container spacing={2}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            p: 4,
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              border: 1,
              borderRadius: "30px",
              borderColor: "#9E6EFF",
              p: 3,
            }}
          >
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box>
                <img
                  src={SuccessMan}
                  alt="LOGO"
                  //   style={{
                  //     maxWidth: "100%",
                  //     height: "auto",
                  //   }}
                />
              </Box>

              <Box sx={{ py: 1 }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: "28px", lg: "30px" },
                  }}
                >
                  Congrats!
                </Typography>
                <Typography sx={{ py: 1 }}>
                  You have created the account, download the app to earn
                  cashbacks.
                </Typography>
              </Box>

              {/* email */}
            </Grid>

            <Box
              sx={{
                py: 3,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // alignSelf: "center",
              }}
            ></Box>
          </Box>
          <Grid item lg={10}>
            <Box
              sx={{
                border: 1,
                borderRadius: "30px",
                borderColor: "#9E6EFF",
                p: 3,
                mt: 3,
              }}
            >
              <Typography
                sx={{ fontSize: "25px", mb: 2, whiteSpace: "nowrap" }}
              >
                Download the app from
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                }}
              >
                <a
                  href="https://apps.apple.com/us/app/xpayback/id6450844844"
                  style={{ textDecoration: "none" }}
                >
                  <Box
                    sx={{
                      background: "white",
                      borderRadius: 12,
                      display: "flex",
                      alignItems: "center",
                      p: 2,
                      gap: 2,
                    }}
                  >
                    <img
                      src={appstore}
                      alt="appstore"
                      style={{ width: "35px" }}
                    />
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "22px",
                        display: { xs: "none", md: "flex" },
                      }}
                    >
                      Playstore
                    </Typography>
                  </Box>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.xpayback.app&pcampaignid=web_share"
                  style={{ textDecoration: "none" }}
                >
                  <Box
                    sx={{
                      background: "white",
                      borderRadius: 12,
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      p: 2,
                      gap: 2,
                    }}
                  >
                    <img
                      src={playStore}
                      alt="appstore"
                      style={{ width: "35px" }}
                    />
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "22px",
                        display: { xs: "none", md: "flex" },
                      }}
                    >
                      Playstore
                    </Typography>
                  </Box>
                </a>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};

export default SuccessPage;
