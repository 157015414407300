import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import { useFormik } from "formik";
import { getRegistrationSMSOTP } from "./signUpservice";
import { toast } from "react-toastify";
import { CustomPhoneNumber } from "./customPhoneNumber";
import { useDispatch, useSelector } from "react-redux";
import { getCountry } from "../redux/Slices/country";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SignUpValidationSchema } from "./validationSchema";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";

const HomePage = () => {
  const country = useSelector(
    (state) => state?.CountryDetails?.country?.data?.country
  );
  // const [countryId, setCountryId] = useState(
  //   country && country.length > 0 ? country[0].id : ""
  // );
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const router = useHistory();
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const url = new URL(window.location.href);
  const key = url.searchParams.get("ref");
  const handleIconClick = () => {
    setOpen(!open);
  };
  useEffect(() => {
    dispatch(getCountry());
  }, [dispatch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const handleChangeCountry = (event) => {
    const selectedCountryCode = event.target.value;
    const selectedCountry = country.find(
      (country) => country?.isd_code === selectedCountryCode
    );
    if (selectedCountry) {
      const { id, isd_code } = selectedCountry;

      // setCountryId(id);
      formik.setFieldValue("country_code", isd_code);
      formik.setFieldValue("country_id", id);
    }
  };

  const formik = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      country_id: "",
      country_code: "",
      phone_number: "",
      password: "",
      referred_by: key,
      promotion_status: false,
    },
    validationSchema: SignUpValidationSchema,
    onSubmit: async (values) => {
      sessionStorage.setItem("formData", JSON.stringify(values));
      try {
        setLoading(true);
        await getRegistrationSMSOTP(
          formik?.values?.email,
          formik?.values?.phone_number,
          formik?.values?.full_name
        )
          .then((response) => {
            const data = response.data;
            setLoading(false);
            if (data?.status_code === 200) {
              toast.success(data?.message);
              router.push(`/otp?ref=${referralName}`);
            }
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message);
            setLoading(false);
          });
      } catch (error) {
        toast.error("Some think went wrong please try again after some times");
        setLoading(false);
      }
    },
  });
  useEffect(() => {
    if (country && country.length > 0) {
      const selected = country?.find((country) => country?.isd_code === "+91");
      // setCountryId(selected?.id);
      formik.setFieldValue("country_code", selected?.isd_code);
      formik.setFieldValue("country_id", selected?.id);
    }
    // eslint-disable-next-line
  }, [country]);

  const [referralName, setReferralName] = useState(null);
  useEffect(() => {
    let isMounted = true;
    const fetchRefererName = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_THIRD_PARTY_API}/referral-validator?referral=${key}`
        );

        if (isMounted) {
          const data = response.data;

          setReferralName(data?.data?.referred_user_name);
        }
      } catch (error) {
        if (isMounted) {
          console.log("error", error);
        }
      }
    };

    fetchRefererName();
    return () => {
      isMounted = false;
    };
  }, [key]);

  return (
    <form>
      {isLoading === true && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div
        style={{
          // width: "100wh",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              p: 4,
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                border: 1,
                borderRadius: "30px",
                borderColor: "#9E6EFF",
                p: 3,
              }}
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={10}
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <img
                    src={logo}
                    alt="LOGO"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      marginBottom: 2,
                    }}
                  />
                  <Typography>
                    Hey, our buddy {referralName ? referralName : "NA"} has
                    invited you to become the
                    <span style={{ color: "#9AFF91" }}> Cashback Ninja </span>
                  </Typography>
                </Box>
                <Box sx={{ py: 1 }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: { xs: "28px", lg: "40px" },
                    }}
                  >
                    Create an accont
                  </Typography>
                  <Typography sx={{ py: 1 }}>
                    Please enter the details to begin
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: { xs: "100%", sm: "80%" },
                    maxWidth: "500px",
                    mb: "16px",
                  }}
                >
                  <FormGroup sx={{ marginY: "0px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 0.5,
                      }}
                    >
                      <InputLabel
                        sx={{ fontWeight: 400, py: "4px", color: "#F2F2F2" }}
                      >
                        Full Name
                      </InputLabel>
                    </Box>
                    <FormControl fullWidth>
                      <TextField
                        value={formik?.values?.full_name}
                        fullWidth
                        id="outlined-basic"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        autoComplete="off"
                        name="full_name"
                        onChange={formik.handleChange}
                        sx={{
                          color: "black",

                          "& fieldset": {
                            border: "none",
                          },

                          "& .MuiInputBase-input ": {
                            color: "black",
                            background: "#F2F2F2",
                            borderRadius: "20px",
                            height: "10px",
                          },
                        }}
                      />
                    </FormControl>
                    <FormHelperText sx={{ color: "red" }}>
                      {formik.touched.full_name && formik.errors.full_name}
                    </FormHelperText>
                  </FormGroup>
                </Box>

                {/* email */}

                <Box
                  sx={{
                    width: { xs: "100%", sm: "80%" },
                    maxWidth: "500px",
                    mb: "16px",
                  }}
                >
                  <FormGroup sx={{ marginY: "0px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 0.5,
                      }}
                    >
                      <InputLabel
                        sx={{ fontWeight: 400, py: "4px", color: "#F2F2F2" }}
                      >
                        Email
                      </InputLabel>
                    </Box>
                    <FormControl fullWidth>
                      <TextField
                        value={formik?.values?.email}
                        fullWidth
                        id="outlined-basic"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        autoComplete="off"
                        name="email"
                        onChange={formik.handleChange}
                        sx={{
                          color: "black",

                          "& fieldset": {
                            border: "none",
                          },

                          "& .MuiInputBase-input ": {
                            color: "black",
                            background: "#F2F2F2",
                            borderRadius: "20px",
                            height: "10px",
                          },
                        }}
                      />
                    </FormControl>
                    <FormHelperText sx={{ color: "red" }}>
                      {formik.touched.email && formik.errors.email}
                    </FormHelperText>
                  </FormGroup>
                </Box>

                {/* number */}
                <Box
                  sx={{
                    width: { xs: "100%", sm: "80%" },
                    maxWidth: "500px",
                    mb: "16px",
                  }}
                >
                  <CustomPhoneNumber
                    formik={formik}
                    CountryDetails={country?.filter(
                      (country) => country.sign_up_available === true
                    )}
                    handleChangeCountry={handleChangeCountry}
                    setOpen={setOpen}
                    name="phone_number"
                    open={open}
                    handleIconClick={handleIconClick}
                    setCountryId={""}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.phone_number && formik.errors.phone_number
                    }
                    helperText={
                      formik.touched.phone_number && formik.errors.phone_number
                    }
                    value={
                      formik?.values?.country_code ||
                      (country && country.length > 0
                        ? country[2]?.isd_code
                        : "")
                    }
                    valuePhone={formik?.values?.phone_number}
                    onChange={(event) => {
                      // const regex = /^\d*$/;
                      // if (regex.test(event.target.value)) {
                      formik.handleChange(event);
                      // }
                    }}
                  />
                </Box>

                {/* pass */}
                <Box
                  sx={{ width: { xs: "100%", sm: "80%" }, maxWidth: "500px" }}
                >
                  <FormGroup sx={{ marginY: "0px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <InputLabel
                        sx={{ fontWeight: 400, py: "4px", color: "#F2F2F2" }}
                      >
                        Password
                      </InputLabel>
                      {showNewPassword ? (
                        <Visibility
                          sx={{ cursor: "pointer" }}
                          onClick={handleClickShowNewPassword}
                        />
                      ) : (
                        <VisibilityOff
                          sx={{ cursor: "pointer" }}
                          onClick={handleClickShowNewPassword}
                        />
                      )}
                    </Box>

                    <FormControl fullWidth>
                      <TextField
                        value={formik?.values?.password}
                        fullWidth
                        id="outlined-basic"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        autoComplete="off"
                        name="password"
                        type={showNewPassword ? "text" : "password"}
                        onChange={formik.handleChange}
                        sx={{
                          // borderRadius: "20px",
                          color: "black",
                          // height: { xs: "40px", sm: "50px" },
                          // background: "#F2F2F2",

                          "& fieldset": {
                            // borderRadius: "20px",
                            // height: { xs: "45px", sm: "55px" },
                            border: "none",
                          },

                          "& .MuiInputBase-input ": {
                            color: "black",
                            background: "#F2F2F2",
                            borderRadius: "20px",
                            height: "10px",
                          },
                        }}
                      />
                    </FormControl>
                    <FormHelperText sx={{ color: "red" }}>
                      {formik.touched.password && formik.errors.password}
                    </FormHelperText>
                  </FormGroup>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                lg={12}
                sx={{ display: "flex", textAlign: "start", mt: 2 }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ color: "blue" }}
                      {...formik.getFieldProps("promotion_status")}
                    />
                  }
                  label={
                    <Typography>
                      By creating an account, I agree to our{" "}
                      <a
                        href="https://xpayback.com/terms-of-use.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#9E6EFF" }}
                      >
                        Terms of use
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://xpayback.com/privacy-policy.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#9E6EFF" }}
                      >
                        Privacy Policy
                      </a>
                    </Typography>
                  }
                />
              </Grid>
              {formik.errors.promotion_status && (
                <FormHelperText sx={{ color: "red" }}>
                  {formik?.touched?.promotion_status &&
                    formik.errors.promotion_status}
                </FormHelperText>
              )}
              <Box
                sx={{
                  py: 3,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // alignSelf: "center",
                }}
              >
                <Grid item lg={8} xs={10}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      borderRadius: 5,
                      background: "#9E6EFF",
                      fontSize: "20px",
                    }}
                    onClick={formik.handleSubmit}
                  >
                    Continue
                  </Button>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
      </div>
    </form>
  );
};

export default HomePage;
