import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getCountry = createAsyncThunk("allCountry", async (thunkApi) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_THIRD_PARTY_API}/get-all-country`,
      {
        headers: {
          accept: "application/json",
        },
      }
    );

    return response;
  } catch (error) {
    return thunkApi.rejectWithVaue(error.response.data);
  }
});
const countrySlice = createSlice({
  name: "allCountry",
  initialState: {
    loading: true,
    successMessage: null,
    user: "",
  },
  reducers: {
    clearMessage: (state) => {
      state.successMessage = null;
    },
  },
  // reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCountry.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCountry.fulfilled, (state, action) => {
        state.loading = false;
        state.country = action.payload.data;
      })
      .addCase(getCountry.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const { clearMessage } = countrySlice.actions;
export default countrySlice.reducer;
