import { configureStore } from "@reduxjs/toolkit";
import country from "./Slices/country";

export const store = configureStore({
  reducer: {
    CountryDetails: country,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: [
          "meta.arg",
          "payload.headers",
          "payload.config",
          "payload.request",
        ],
        ignoredPaths: ["payload.headers", "payload.config", "payload.request"],
      },
    }),
});
