// import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Main from "./components/main";
import HomePage from "./pages/homepage";
import OtpPage from "./pages/otpPage";
import SuccessPage from "./pages/successPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Router>
      <Main>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/otp" component={OtpPage} />
          <Route path="/success" component={SuccessPage} />
        </Switch>
      </Main>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Router>
  );
}

export default App;
